@font-face {
  font-family: "IBMPlexMono";
  src: local("IBMPlexMono"),
    url("./assets/fonts/IBMPlexMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "FormaDJRBanner";
  src: local("FormaDJRBanner"),
    url("./assets/fonts/FormaDJRBanner-Bold-Edu.ttf") format("truetype");
}

@font-face {
  font-family: "FormaDJRDeck";
  src: local("FormaDJRDeck"),
    url("./assets/fonts/FormaDJRDeck-Regular-Edu.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "IBMPlexMono", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
h3,
h4,
h5 {
  font-family: "IBMPlexMono", "Roboto";
}

h1 {
  font-family: "FormaDJRBanner", "Roboto";
  font-weight: bold;
}

h2 {
  font-family: "FormaDJRDeck", "Roboto";
  font-weight: bold;
}

h3 {
  font-weight: bold;
}

.pageContainer {
  padding: 40px 0;
  min-height: 100vh;
  /* height: 100vh; */
}

a {
  color: #000;
  text-decoration: underline;
  font-weight: 300;
}

.socialLink {
  font-size: 24;
}

.modal {
  display: flex !important;
}

/* .modal::before {
  content: "";
  display: inline-block;
  height: 100%;
  margin-right: -4px;
  vertical-align: middle;
} */

.modal-dialog {
  margin: auto;
  maxHeight: 70vh;
  width: auto;
}
